/* Able - Modal */
.full-modal .modal-lg {
  min-width: 99%;
  margin: 0 auto;
}

.full-modal .modal-lg .modal-content {
  min-height: 96vh;
  top: 2vh;
}
.full-modal .modal-body {
  padding-bottom: 75px;
}

.full-modal .modal-footer {
  display: block !important;
  position: absolute;
  min-width: 100%;
  bottom: 0;
}

.modal-header button {
  font-size: 72px;
}

.modal-header .close {
  padding: 0 20px !important;
}

.modal .modal-footer {
  overflow: hidden;
}

h1.modal-title {
  font-weight: 400;
}

.modal-body .nav-link {
  font-size: 20px !important;
}

.btn-xlg .button-muted {
  font-size: 12px;
  display: block;
  line-height: normal;
}

/* Bootstrap - Modal */
.modal .modal-header h1.modal-title {
  font-size: 2rem;
  color: #fff;
}

.modal .modal-header {
  background: #ff784f !important;
}

.modal .modal-header .close {
  margin: -0.8rem -1rem -1rem auto !important;
  color: #fff !important;
  outline: 0 !important;
}

.modal .modal-footer .buttons-left {
  float: left;
}

.modal .modal-footer .buttons-right {
  float: right;
}
