.pcoded[theme-layout="horizontal"] {
    .pcoded-container {
        position: relative;
    }

    .pcoded-header {
        z-index: 1024;
    }

    .pcoded-navbar {
        display: block;
        height: 50px;
        width: 100%;
        z-index: 1023;
        box-shadow: 0 2px 7px -3px rgba(0, 0, 0, 0.4);

        .pcoded-item .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
            opacity: 1;
            visibility: visible;
            transform: rotateX(0deg);
        }

        li.pcoded-hasmenu > a:after {
            right: 3px;
            top: -3px;
        }
    }

    #styleSelector {
        z-index: 1024;
    }

    &[pcoded-device-type="phone"],
    &[pcoded-device-type="tablet"] {
        .p-chat-user,
        .showChat_inner {
            top: 107px;
        }
    }

    &[pcoded-device-type="desktop"] {
        .navbar-logo .mobile-menu,
        .pcoded-brand .menu-toggle {
            display: none;
        }
      .page-header {
        padding-top: 50px;
      }
    }



    .pcoded-navbar {
        .pcoded-item {
            &[item-border="true"] {
                &[item-border-style="dashed"] > li > a {
                    border-right-style: dashed;
                }

                &[item-border-style="dotted"] > li > a {
                    border-right-style: dotted;
                }

                &[item-border-style="solid"] > li > a {
                    border-right-style: solid;
                }

                &[item-border-style="dashed"] .pcoded-hasmenu .pcoded-submenu li > a {
                    border-bottom-style: dashed;
                }

                &[item-border-style="dotted"] .pcoded-hasmenu .pcoded-submenu li > a {
                    border-bottom-style: dotted;
                }

                &[item-border-style="solid"] .pcoded-hasmenu .pcoded-submenu li > a {
                    border-bottom-style: solid;
                }
            }

            &[item-border="false"] > li > a {
                border-right-width: 0;
            }

            &[item-border="true"] > li > a {
                border-right-width: 1px;
                cursor: pointer;
            }

            &[subitem-border="false"] .pcoded-hasmenu .pcoded-submenu li > a {
                border-bottom-width: 0;
            }

            &[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li > a {
                border-bottom-width: 1px;
            }
        }
    }

    .pcoded-navbar .pcoded-item {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        > li {
            display: block;
            list-style: outside none none;
            margin: 0;
            padding: 5px;
            position: relative;

            > a {
                display: block;
                font-size: 13px;
                padding: 5px 20px;
                text-decoration: none;
                position: relative;
                @media only screen and (max-width: 1200px) and (min-width: 992px) {
                    padding: 5px;
                }
            }

            &.pcoded-hasmenu > a {
                line-height: 1.85;
            }
        }

        .pcoded-hasmenu .pcoded-submenu {
            list-style: outside none none;
            margin: 0;
            padding: 5px 0;
            box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.4);

            li {
                display: block;
                list-style: outside none none;
                margin: 0;
                padding: 3px 10px;
                position: relative;

                > a {
                    display: block;
                    font-size: 14px;
                    padding: 7px 10px;
                    text-decoration: none;
                    position: relative;
                    &:after{
                        padding-top: 7px;
                        line-height: 1;
                    }
                }
            }
        }

        > li > a > .pcoded-micon {
            font-size: 14px;
            padding: 0;
        }

        &.pcoded-search-item > li > a > .pcoded-micon {
            padding: 0 5px 0 7px;
        }

        .pcoded-hasmenu .pcoded-submenu li > a .pcoded-micon {
            font-size: 13px;
            padding-right: 5px;
        }
    }

    &[horizontal-layout="widebox"] {
        .pcoded-container {
            width: 100%;
        }

        .pcoded-content .pcoded-wrapper,
        .pcoded-header .pcoded-wrapper,
        .pcoded-navbar .pcoded-wrapper {
            display: block;
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
        }
    }

    .pcoded-navbar .pcoded-item {
        > li {
            float: left;
            padding: 5px 10px;
        }

        li {
            a {
                border-radius: 2px;
              transition: all 0s linear;
            }
            &.active,
            &.pcoded-trigger,
            &:hover {
                > a{
                    box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);
                }
            }
        }
        .pcoded-hasmenu .pcoded-submenu {
            position: absolute;
            min-width: 200px;
            z-index: 1031;
            border-top-width: 0;
            top: 100%;

            li > a {
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                > .pcoded-mtext{
                    transition: all 0s ease-in-out;
                    &:before{
                        transition: all 0s ease-in-out;
                    }
                }
            }
        }

        .pcoded-submenu .pcoded-submenu {
            position: absolute;
            min-width: 250px;
          z-index: 1032;
            left: 100%;
            top: -1px;
        }

        .pcoded-hasmenu .pcoded-submenu {
            opacity: 0;
            visibility: hidden;
            transform-origin: 50% 50%;
            transition: transform 0.3s, opacity 0.3s;
            transform-style: preserve-3d;
            transform: rotateX(-90deg);
        }

        .pcoded-submenu li.pcoded-hasmenu > a:after {
            margin-left: 3px;
            position: relative;
            float: right;
            top: -3px;
        }
    }

    .pcoded-navbar .pcoded-item > li.pcoded-hasmenu {
        > a:after,
        &.active > a:after,
        &.pcoded-trigger > a:after {
            font-style: normal;
            margin-left: 3px;
            padding-left: 5px;
            position: relative;
        }
    }

    .pcoded-navbar .pcoded-item > .pcoded-hasmenu.edge.pcoded-trigger > .pcoded-submenu {
        right: 0;
    }

    &[horizontal-placement="top"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger.active {
        .pcoded-submenu {
            border-top-style: solid;
            border-top-width: 1px;
        }
    }

    .pcoded-navbar .pcoded-item[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu > li:last-child > a {
        border-bottom-color: transparent;
    }

    &[pcoded-device-type="tablet"] .pcoded-navbar {
        .pcoded-item {
            &.pcoded-search-item > li > a > .pcoded-micon {
                display: block;
            }
        }
    }

    &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-inner-navbar,
    &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-inner-navbar {
        position: relative;
    }

    &[pcoded-device-type="tablet"],
    &[pcoded-device-type="phone"] {
        .pcoded-navbar {
            height: auto;
            padding: 10px 10px 0;
            position: absolute;

            .pcoded-inner-navbar {
                height: auto;
                position: relative;
            }
            opacity: 0;
            visibility: hidden;
            transform-origin: 50% 50%;
            transition: transform 0.3s, opacity 0.3s;
            transform-style: preserve-3d;
            transform: rotateX(-90deg);

            &.show-menu {
                opacity: 1;
                visibility: visible;
                transform: rotateX(0deg);
            }
        }
    }

    &[pcoded-device-type="tablet"],
    &[pcoded-device-type="phone"] {
        .pcoded-navbar .pcoded-item {
            display: block;
            float: none;
            position: relative;
            width: 100%;
            z-index: 1;

            > li {
                float: none;
                display: block;
                position: relative;
            }

            &[item-border="true"] > li {
                > a {
                    border-right-width: 0;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                }

                &.pcoded-trigger > a {
                    border-bottom-width: 0;
                }
            }

            > .pcoded-hasmenu {
                > .pcoded-submenu {
                    min-width: 100%;
                    z-index: 2;
                }

                &.pcoded-trigger > .pcoded-submenu {
                    position: relative;
                }

                > .pcoded-submenu > .pcoded-hasmenu {
                    > .pcoded-submenu {
                        left: 0;
                        min-width: 100%;
                        right: 0;
                        top: 0;
                        z-index: 1;
                    }

                    &.pcoded-trigger {
                        > .pcoded-submenu,
                        > .pcoded-submenu > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
                            position: relative;
                        }
                    }

                    > .pcoded-submenu > .pcoded-hasmenu > .pcoded-submenu {
                        left: 0;
                        min-width: 100%;
                        right: 0;
                        top: 0;
                        z-index: 1;
                    }
                }
            }
        }

        &[horizontal-placement="bottom"] .pcoded-navbar {
            bottom: auto;
        }

        .pcoded-navbar .pcoded-item > li.pcoded-hasmenu {
            > a:after,
            &.active > a:after,
            &.pcoded-trigger > a:after {
                position: absolute;
                right: 13px;
            }
        }
    }

    &[pcoded-device-type="phone"],
    &[pcoded-device-type="tablet"] {
        .pcoded-header {
            height: auto;
            padding-top: 0;
        }

        .pcoded-navbar .pcoded-item {
            .pcoded-hasmenu .pcoded-submenu {
                border-top: none;
                margin: 15px auto;
                min-width: 90%;
                width: 90%;
                box-shadow: none;

                .pcoded-hasmenu .pcoded-submenu {
                    min-width: 90%;
                    width: 90%;

                    .pcoded-hasmenu .pcoded-submenu {
                        min-width: 90%;
                    }
                }
            }
        }
    }

    .pcoded-header[pcoded-header-position="relative"] ~ .pcoded-main-container {
        top: -1px;
    }
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-submenu > li a .pcoded-badge {
    top: 7px;
    right: 20px;
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu.edge > .pcoded-submenu {
    left: auto;
    right: 100%;
}

.pcoded-main-container {
    clear: both;
}

.pcoded .pcoded-navbar .pcoded-item:after {
    display: none;
}
.pcoded{
  &[pcoded-device-type="desktop"][theme-layout="horizontal"]{
    @media only screen and (max-width: 1366px){
      .pcoded-item{
        li{
          padding: 5px 5px;
          a{
            padding: 5px 10px;
          }
        }
      }
    }
  }
}
body {
    &.horizontal-icon,
    &.horizontal-icon-fixed {
        .pcoded {
            &[theme-layout="horizontal"] .page-header {
                padding-top: 100px;
            }
            .pcoded-navbar {
                height: 80px;

                .pcoded-item > li {
                    > a > {
                        .pcoded-micon {
                            display: block;
                            text-align: center;
                            margin: 0 auto;
                        }
                    }

                    &.pcoded-hasmenu > a {
                        line-height: 30px;
                    }
                }
            }

            &[pcoded-device-type="desktop"] {
                .pcoded-navbar {
                    .pcoded-item > li {
                        &.pcoded-hasmenu > a {
                            height: 70px;
                        }
                    }
                }
            }
        }
    }
}
