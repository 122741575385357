hr.scw-hr {
  margin: 10px 0 20px 0;
  border-color: #53585f;
  border-style: solid;
}

app-datatable-row {
  display: contents;
}

scw-mat-form {
  & div.inner-form-section {
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border: 1px solid #a8a8a8;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;

    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
  }
}
