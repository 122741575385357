// -------- Variables -----------
$scw-font-family: 'Open Sans', sans-serif;
$h1-font-size: 32px;
$h2-font-size: 28px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 18px;
$h6-font-size: 16px;

$h1-font-weight: 600;
$h2-font-weight: 600;
$h3-font-weight: 600;
$h4-font-weight: 600;
$h5-font-weight: 600;
$h6-font-weight: 600;
$scw-header-class-name: 'scw-header';

.#{$scw-header-class-name} {
  margin-bottom: 16px;
}

// --------- Headings -----------
h1,
h2,
h3,
h4,
h5,
h6 {
  &.#{$scw-header-class-name} {
    font-family: $scw-font-family !important;
  }
}

h1 {
  &.#{$scw-header-class-name} {
    font-size: $h1-font-size !important;
    font-weight: $h1-font-weight !important;
  }
}

h2 {
  &.#{$scw-header-class-name} {
    font-size: $h2-font-size !important;
    font-weight: $h2-font-weight !important;
  }
}

h3 {
  &.#{$scw-header-class-name} {
    font-size: $h3-font-size !important;
    font-weight: $h3-font-weight !important;
  }
}

h4 {
  &.#{$scw-header-class-name} {
    font-size: $h4-font-size !important;
    font-weight: $h4-font-weight !important;
  }
}

h5 {
  &.#{$scw-header-class-name} {
    font-size: $h5-font-size !important;
    font-weight: $h5-font-weight !important;
  }
}

h6 {
  &.#{$scw-header-class-name} {
    font-size: $h6-font-size !important;
    font-weight: $h6-font-weight !important;
  }
}
// --------- Font Sizes (font-size) -----------
$scw-font-sizes: 32px, 28px, 24px, 20px, 18px, 16px, 14px, 10px;

@each $size in $scw-font-sizes {
  .scw-fs-#{$size} {
    --scw-fs: #{$size};
  }
}
// --------- Font Weight (font-weight) -----------
$scw-font-weights: 800, 700, 600, 500, 400;

@each $weight in $scw-font-weights {
  .scw-fw-#{$weight} {
    --scw-fw: #{$weight};
  }
}
