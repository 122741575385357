@use 'ngx-scss/waves';
@use 'ngx-scss/tab';
@use 'ngx-scss/list';
@use 'ngx-scss/pnotify';
@use 'ngx-scss/rating';
@use 'ngx-scss/carousel';
@use 'ngx-scss/tag-input';
@use 'ngx-scss/table';
@use 'ngx-scss/accordion';
@use 'ngx-scss/task-board';
@use 'ngx-scss/ripple';
@use 'variables';

.header-navbar {
  .navbar-wrapper {
    .navbar-container {
      .nav-right li {
        padding: 0 0px;
        .badge {
          right: 4px;
        }
      }
      .header-notification {
        .show-notification,
        .profile-notification {
          display: block;
          padding: 0;
          right: 0;
          > li {
            &:first-child {
              padding-top: 15px;
            }
            &:last-child {
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li,
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li {
  border-top: none;
}

.header-notification.ripple,
.show-notification.show {
  overflow: visible;
}

.showChat,
.showChat_inner {
  display: block;
  height: 100vh;
  overflow: hidden;
  &.out {
    .back_chatBox i,
    .back_friendlist i {
      display: none;
    }
    .users-main {
      width: 0;
      overflow: hidden;
    }
  }
  &.ng-animating {
    .users-main {
      width: 0;
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 576px) {
  .pcoded .header-navbar .navbar-wrapper .navbar-container .header-notification {
    .profile-notification:before {
      left: calc(100% - 60px);
    }
    .show-notification:before {
      right: calc(100% - 60px);
    }
  }
}

@media only screen and (max-width: 992px) {
  .header-navbar .navbar-wrapper .navbar-container .nav-right {
    display: block;
    overflow: hidden;
    &.nav-on {
      overflow: visible;
    }
    &.nav-on.nav-off {
      overflow: hidden;
    }
  }

  .activities-total-duration-metric {
    .mat-flat-button {
      min-width: 33px !important;
      padding: 0 6px !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .activities-total-duration-metric {
    .mat-flat-button {
      min-width: 45px !important;
      padding: 0 6px !important;
    }
  }
}

.main-menu .main-menu-content .more-details {
  display: block;
}

.pcoded .pcoded-navbar .pcoded-item {
  padding: 0;
}

perfect-scrollbar.disabled .ps__rail-y {
  display: none;
}

perfect-scrollbar.disabled .ps--scrolling-y .ps_rail-x {
  bottom: 0 !important;
}

perfect-scrollbar.disabled .ps--scrolling-y .ps_rail-y {
  top: 0 !important;
}

#styleSelector {
  z-index: 101;
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__thumb-y {
    width: 6px;
  }
  .theme-color a {
    margin: 5px 4px 5px 0;
  }
}

//====== menu open css ===========
body .pcoded[theme-layout='vertical'] {
  &[vertical-nav-type='collapsed'] {
    perfect-scrollbar.disabled {
      overflow: visible;
      .ps {
        overflow: visible !important;
      }
    }
  }
  .pcoded-item .pcoded-hasmenu {
    .pcoded-submenu {
      width: 80%;
      list-style: outside none none;
      margin: 15px auto;
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }

    &.pcoded-trigger .pcoded-submenu {
      -webkit-transform-origin: 0 0;
      transform-origin: 10% 10%;
      transition: transform 0.5s, opacity 0.5s;
    }

    .pcoded-submenu {
      transform-style: preserve-3d;
      transform: rotateX(-90deg);
      //display: none;
    }
    &.pcoded-trigger.active > .pcoded-submenu {
      display: block;
    }
    &.pcoded-trigger > .pcoded-submenu {
      position: relative;
      display: block;
      opacity: 1;
      visibility: visible;
      transform: rotateX(0deg);
    }
  }
}

$icon-color-ang: #448aff, #b71c1c, #4caf50, #d84315, #4527a0, #37474f, #7c4dff, #ff5370;
.pcoded {
  &[nav-type='st1'] {
    .d-color {
      $i: 1;
      @for $k from 1 through 3 {
        @each $value in $icon-color-ang {
          > .pcoded-item {
            &:nth-child(#{$i + 1}) {
              > li > a > .pcoded-micon {
                color: $value;
              }
            }
          }
          $i: $i + 1;
        }
      }
    }
  }
  .d-color {
    &:after {
      content: '';
      background-color: #e4e9eb;
      width: 80%;
      height: 1px;
      position: absolute;
      left: 10%;
      bottom: 10px;
    }
    padding-bottom: 20px;
    position: relative;
  }
  &[theme-layout='horizontal'] {
    .d-color {
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
  .pcoded-navbar .pcoded-item {
    padding-bottom: 0;
    &:after {
      display: none;
    }
  }
  &[nav-type='st1'],
  &[nav-type='st2'] {
    .pcoded-item.pcoded-left-item {
      > li {
        > a > .pcoded-micon {
          b {
            display: none;
          }
        }
      }
    }
  }
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-control:lang(en)::before {
  content: 'Browse';
}
.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-file-control:lang(en):empty::after {
  content: 'Choose file...';
}

.pcoded-inner-content {
  margin-top: 0;
}

#ngb-open-p3 .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745;
}

#ngb-open-p3 .btn-outline-warning:hover {
  color: #fff;
  background-color: #ffa11a !important;
  border-color: #ffa11a;
}

#ngb-open-p3 .btn-outline-danger:hover {
  color: #fff;
  background-color: #ff1515 !important;
  border-color: #ff1515;
}

#ngb-open-p3 .btn-outline-primary:hover {
  color: #fff;
  background-color: #0764ff !important;
  border-color: #0764ff;
}

.filter-bar .navbar-nav .dropdown-menu {
  position: absolute;
}

//===========   bradcumb  =================
.pcoded-inner-content {
  padding: 10px;
  margin-top: -50px;
}

.tooltip-error {
  position: absolute;
  right: 30px;
  top: 7px;
}

.custom-range {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  &.focused {
    background-color: #e6e6e6;
  }
  &.range,
  &:hover {
    background-color: variables.$color-blue;
    color: white;
  }
}

.faded {
  opacity: 0.5;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;
  &:hover,
  &.focused {
    background-color: #e6e6e6;
  }
}

.weekend {
  background-color: #999;
  border-radius: 1rem;
  color: white;
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: block;
    flex: 240px;
  }
}

.table-styling .table-info,
.table-styling.table-info {
  background-color: #00bcd4;
  color: #fff;
  border: none;
  border-top: 3px solid #00bcd4;
  border-bottom: 3px solid #00bcd4;
}

.login-block .auth-box {
  margin: 65px auto 0 auto !important;
}

//=== wizard
.ngx-form-wizard {
  .nav-item {
    a.nav-link {
      background: #eee;
      color: #aaa;
      margin: 0 0.5em 0.5em;
      padding: 1em 1em;
      border-radius: 5px;
      &.active {
        background-color: variables.$primary-color;
        color: #fff;
      }
    }
  }
  .nav-tabs {
    border-bottom: none;
  }
  .tab-pane {
    border: 1px solid variables.$theme-border;
    padding: 20px;
    margin: 8px;
    border-radius: 5px;
  }
}
.arc-wizard {
  &.arc-custom {
    .steps-indicator li:after {
      border-radius: 5px !important;
    }
  }
  .centered-content {
    text-align: center;
  }
  .wizard-steps {
    border-radius: 5px;
    border: 1px solid variables.$theme-border;
    padding: 20px;
  }

  .small ul.steps-indicator li.current:after,
  .small ul.steps-indicator li.editing:after {
    background-color: variables.$primary-color !important;
  }

  .large-filled-symbols ul.steps-indicator li.current:after,
  .large-filled-symbols ul.steps-indicator li.editing:after {
    background-color: variables.$primary-color !important;
    color: #fff !important;
  }

  .large-filled-symbols ul.steps-indicator li.done:after {
    color: #fff !important;
  }

  .large-empty-symbols ul.steps-indicator li.current:after,
  .large-empty-symbols ul.steps-indicator li.editing:after {
    border-color: variables.$primary-color !important;
    color: variables.$primary-color !important;
  }
}

// range-slider

.ag-slider .noUi-horizontal {
  height: 16px;
  background: transparent;
  border-radius: 0px;
  border: none;
  border-top: 1px solid #d3d3d3;
  box-shadow: none;
  cursor: pointer;
  .noUi-handle {
    width: 20px;
    height: 20px;
    top: -10px;
    border: 1px solid transparent;
    border-radius: 100%;
    background-color: #4680ff;
    box-shadow: none;
    &:after,
    &:before {
      background: transparent;
    }
  }
  .noUi-connects {
    padding: 10px;
    top: -10px;
    .noUi-connect {
      background: #4680ff;
      height: 3px;
      top: 8px;
    }
  }

  .noUi-pips-horizontal {
    top: 0;
    padding: 0;
  }
  .noUi-tooltip {
    border: 1px solid variables.$inverse-color;
    border-radius: 5px;
    background: variables.$inverse-color;
    color: #fff;
    padding: 5px 15px;
    transform: translate(-50%, -15px);
    &:after {
      content: '';
      position: absolute;
      border: 6px solid transparent;
      border-bottom-color: variables.$inverse-color;
      border-right-color: variables.$inverse-color;
      bottom: -10px;
      left: 50%;
      transform: rotate(45deg) translate(-50%, 0);
    }
  }
}

// ===========   tour
ngb-popover-window {
  &:before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: none;
  }
  .arrow,
  .popover-header,
  .popover-body {
    position: relative;
    z-index: 9;
  }
  .popover-body {
    background: #fff;
    border-radius: 0 0 3px 3px;
  }
}
.touranchor--is-active {
  z-index: 2999;
  position: relative;
  padding: 5px 10px;
  background: #fff;
}

//==========================   other scss   ===============
.client-map .client-contain {
  margin-left: 10px !important;
}
@media only screen and (max-width: 768px) {
  wizard.vertical {
    .vertical a {
      display: none;
    }
  }

  .activities-total-duration-metric {
    .mat-flat-button {
      min-width: 33px !important;
      padding: 0 6px !important;
    }
  }
}

.pcoded[layout-type='dark'],
body.dark {
  .page-item.active a {
    background-color: variables.$primary-color;
  }

  google-chart rect {
    fill: transparent;
  }

  .popover-header,
  .taskboard-task-title,
  .touranchor--is-active,
  .well.ngb-drop-zone,
  .cal-month-view .cal-cell-row:hover,
  .google-visualization-table-table {
    color: variables.$dark-layout-color;
  }

  .cal-month-view .cal-cell-row .cal-cell:hover,
  .cal-month-view .cal-cell.cal-has-events.cal-open,
  .taskboard-header {
    color: variables.$inverse-color;
  }

  .node-content-wrapper-focused {
    background: #e7f4f9;
    color: variables.$inverse-color;
  }

  .node-content-wrapper:hover {
    background: #f7fbff;
    color: variables.$inverse-color;
  }

  .node-content-wrapper-active,
  .node-content-wrapper-focused,
  .node-content-wrapper:hover {
    -webkit-box-shadow: inset 0 0 1px #999;
    box-shadow: inset 0 0 1px #999;
  }

  .node-content-wrapper-active,
  .node-content-wrapper.node-content-wrapper-active:hover,
  .node-content-wrapper-active.node-content-wrapper-focused {
    background: #beebff;
    color: variables.$inverse-color;
  }

  perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y,
  .ps__rail-x:focus,
  .ps__rail-x:hover,
  .ps__rail-y:focus,
  .ps__rail-y:hover {
    background-color: transparent;
  }

  .btn-light {
    color: #fff;
    &:hover {
      color: variables.$inverse-color;
    }
  }

  .bg-light {
    background-color: transparent !important;
  }
}

.error-400,
.error-403,
.error-404,
.error-500,
.error-503 {
  position: fixed;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
}

.error-400 h1,
.error-403 h1,
.error-404 h1,
.error-500 h1,
.error-503 h1 {
  padding: 0;
  margin-bottom: 40px;
  font-size: 210px;
  font-weight: 900;
  line-height: 210px;
  color: variables.$inverse-color;
}

.error-400 h5,
.error-403 h5,
.error-404 h5,
.error-500 h5,
.error-503 h5 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 400;
  color: variables.$primary-color;
}

.coming {
  background-size: cover;
  position: fixed;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.comming-soon {
  text-align: center;
  color: #fff;
}

.comming-soon h1 {
  font-size: 54px;
  font-weight: 600;
}

.comming-soon p {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}

.coming-social {
  padding-top: 50px;
  text-align: center;
}

.comming-soon .timer .time {
  font-size: 90px;
  color: #fff;
}

.comming-soon .timer .text {
  font-size: 24px;
  font-weight: 400;
  color: variables.$primary-color;
}

.coming-social a {
  margin-right: 20px;
  color: #fff;
  font-size: 30px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb50 {
  margin-bottom: 50px;
}

.squeezebox .sb-item {
  overflow: hidden;
  .sb-item-body {
    height: 0;
    overflow: hidden;
    transition: 0.5s height;
  }
}

.form-control {
  &::-moz-placeholder {
    color: #ccc;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #ccc;
  }
  &::-webkit-input-placeholder {
    color: #ccc;
  }
}

.header-navbar .navbar-wrapper .header-search .main-search {
  padding: 6px 0;
  display: block;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.fit-content {
  width: fit-content;
}

.cursor-progress {
  cursor: progress !important;

  * {
    pointer-events: none;
  }
}

.finalizer-switch {
  small {
    background: #ffc107 !important;
  }
  .checked {
    background-color: #28a745 !important;
    small {
      background: rgb(255, 255, 255) !important;
    }
  }
}

.tiny-table-td td,
.tiny-table-td th {
  padding: 0 0.75rem !important;
  height: 38px;
  line-height: 38px;
  font-size: 15px;
}

.tiny-table-td td.no-padding {
  padding: 0 !important;
}

#styleSelector {
  .rightbar-button {
    background: #37474f;
  }
}

.page-mode {
  #dashboard-switch {
    > span {
      top: 2px;
    }
  }
}

.work-order-field {
  .isDisabled {
    cursor: not-allowed;
    color: grey;
  }
}

.wo-switch-tooltip {
  .tooltip {
    .tooltip-inner {
      width: 500px !important;
    }
  }
}

.home-screen-metrics {
  .actions {
    width: 20% !important;
  }
}

.action-column {
  ngb-popover-window .popover-body {
    height: 150px;
    overflow-y: auto;
  }
}

.metric-tree-checkbox {
  .sub-title {
    font-size: 11px !important;
    color: #999 !important;
  }
}

.scheduler-resource-setting {
  .tree-checkbox-padding,
  .c-btn {
    font-size: 13px;
  }
}

.sensor-report {
  .sensor-header {
    text-align: center;
    padding: 0.75rem !important;

    &:first-child {
      position: -webkit-sticky !important;
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }

  .custom-picker {
    height: 40px;
  }

  tbody {
    tr:nth-child(odd) {
      td:first-child {
        background: white !important;
        z-index: 2;
      }
    }
    tr:nth-child(even) {
      td:first-child {
        background: #f3f3f3 !important;
        z-index: 2;
      }
    }
  }
}

td.is-status {
  padding: 0 !important;
}

.wizard-steps {
  width: 100%;
}

.mat-form-field-underline {
  height: 0 !important;
}

.mat-form-field-infix {
  background: variables.$white;
  border: none !important;
  padding: 0 !important;
  display: flex !important;
  flex-direction: column;
}

.mat-form-field-ripple {
  display: none;
}

.button-group {
  .mat-button-wrapper {
    display: block !important;
  }
}

.nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.select-wo-modal-footer {
  padding: 12px 0 0 0 !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);

  .buttons-left {
    margin-right: auto;
  }
}

.custom-css {
  width: 100px;
}

.mat-button-wrapper {
  i,
  em {
    vertical-align: baseline !important;
  }

  .asset-button-icon {
    vertical-align: middle !important;
  }
}

.activity-review-page .mat-flat-button .mat-button-wrapper > * {
  vertical-align: initial !important;
}

.list-view-textarea-comment-placeholder {
  .mat-form-field-label-wrapper {
    padding-bottom: 0.5em !important;

    .mat-form-field-label {
      top: 24px;
      left: 10px;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0.25em;
  }
}

.metric-info {
  font-size: 9px;
  margin-bottom: 0;
  color: grey;
}
.metric-info-list {
  ul {
    list-style-type: disc;
    padding-inline-start: 40px;
    li {
      color: grey;
      font-size: 12px;
    }
  }
}

.layout-accordion {
  .mat-expansion-panel {
    overflow: initial;
    margin-bottom: 10px;
  }

  .mat-expansion-panel-content {
    overflow: hidden;
  }
}

.lines-popover {
  max-height: 200px;
  overflow-y: scroll;
  pointer-events: all;
}

.metric-select {
  span {
    font-size: 12px;
    font-weight: 500;
  }

  .list-area {
    label {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.phase-buttons {
  .scw-button-group-item-button {
    .mat-button-ripple.mat-ripple,
    .mat-button-focus-overlay .mat-button-wrapper {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    span {
      display: contents !important;
    }
  }
}

.work-order-page-placeholder {
  .mat-form-field-label-wrapper {
    padding-bottom: 0.5em !important;

    .mat-form-field-label {
      color: #a8a8a8 !important;
      top: 32px !important;
      left: 10px !important;
    }
  }
}

.toast-message.link-message a {
  text-decoration: underline !important;
}

.selected-list {
  .wo-filter-dropdown-label {
    margin-top: 0 !important;
    margin-left: 0 !important;
  }
}
