.basic-list, .list-view-media {
  margin-bottom: 30px;
}

.basic-list {
  li {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    &:last-child {
      border: 0;
      padding-bottom: 0;
    }
  }
  &.list-icons li, &.list-icons-img li {
    margin-left: 50px;
    position: relative;
  }
}

.list-icons i {
  left: -64px;
  top: 0;
  bottom: -1px;
  width: 64px;
  padding: 8px 0;
  float: left;
  position: absolute;
}

.basic-list p {
  margin: 0;
}

.list-icons-img img {
  left: -60px;
  top: 0;
  bottom: -1px;
  width: 40px;
  position: absolute;
  border-radius: 5px;
}

.list-view li {
  button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .user-card {
    margin-bottom: 20px;
  }
  &:last-child .user-card {
    margin-bottom: 0;
  }
}
